// import {
// LineChartOutlined,
// GiftOutlined,
// ShoppingCartOutlined,
// TeamOutlined,
// SolutionOutlined,
// SettingOutlined,
// PercentageOutlined,
// } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

// const dashBoardNav = [
//   {
//     key: "dashboards",
//     path: `${APP_PREFIX_PATH}/dashboards`,
//     title: "sidenav.dashboard",
//     icon: LineChartOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

// const promotionCampaignNav = [
//   {
//     key: "campaigns",
//     path: `${APP_PREFIX_PATH}/campaigns`,
//     title: "sidenav.campaigns",
//     icon: GiftOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

// const discountTreeNav = [
//   {
//     key: "discount",
//     path: `${APP_PREFIX_PATH}/dicount`,
//     title: "sidenav.discount",
//     icon: ShoppingCartOutlined,
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "discount-default",
//         path: `${APP_PREFIX_PATH}/dicount/default`,
//         title: "sidenav.discount",
//         icon: ShoppingCartOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
// ];

const homeNav = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "sidenav.home",
    breadcrumb: false,
    icon: "bi:house",
    submenu: [
      {
        key: "home-default",
        path: `${APP_PREFIX_PATH}/home`,
        title: "sidenav.home",
        icon: "bi:house",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const voucherTreeNav = [
  {
    key: "vouchers",
    path: `${APP_PREFIX_PATH}/vouchers`,
    title: "sidenav.vouchers",
    icon: "bi:ticket",
    breadcrumb: true,
    submenu: [
      {
        key: "vouchers-default",
        path: `${APP_PREFIX_PATH}/vouchers`,
        title: "sidenav.vouchers",
        icon: "bi:ticket",
        breadcrumb: true,
        submenu: [
          {
            key: "list-voucher",
            path: `${APP_PREFIX_PATH}/vouchers/list`,
            title: "sidenav.vouchers.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "list-release",
            path: `${APP_PREFIX_PATH}/vouchers/release/list`,
            title: "sidenav.vouchers.release.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

// const customerNav = [
//   {
//     key: "customer",
//     path: `${APP_PREFIX_PATH}/customer`,
//     title: "sidenav.customer",
//     icon: TeamOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

const partnerTreeNav = [
  {
    key: "partnership",
    path: `${APP_PREFIX_PATH}/partnership`,
    title: "sidenav.partners",
    icon: "bi:briefcase",
    breadcrumb: false,
    submenu: [
      {
        key: "partnership-default",
        path: `${APP_PREFIX_PATH}/partnership`,
        title: "sidenav.partnership",
        icon: "bi:briefcase",
        breadcrumb: true,
        submenu: [
          {
            key: "partner-campaign-list",
            path: `${APP_PREFIX_PATH}/partnership/campaign/list`,
            title: "sidenav.partnership.campaign.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "list-partnership",
            path: `${APP_PREFIX_PATH}/partnership/list`,
            title: "sidenav.partnership.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const partnerClientTreeNav = [
  {
    key: "partnerclient",
    path: `${APP_PREFIX_PATH}/partner-client`,
    title: "sidenav.partnerClient",
    icon: "bx:briefcase",
    breadcrumb: false,
    submenu: [
      {
        key: "partnerclient-default",
        path: `${APP_PREFIX_PATH}/partner-client/info`,
        title: "sidenav.partnerclient.info",
        icon: "bi:person-circle",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "partnerclient-campaign",
        path: `${APP_PREFIX_PATH}/partner-client/campaign`,
        title: "sidenav.partnerclient.campaign",
        icon: "bi:gift",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "partnerclient-release",
        path: `${APP_PREFIX_PATH}/partner-client/release/list`,
        title: "sidenav.partnerclient.release",
        icon: "bi:file-earmark-text",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const gifVoucherTreeNav = [
  {
    key: "giftVouchers",
    path: `${APP_PREFIX_PATH}/gift-vouchers`,
    title: "sidenav.giftVouchers",
    icon: "bi:gift",
    breadcrumb: false,
    submenu: [
      {
        key: "giftVouchersDefault",
        path: `${APP_PREFIX_PATH}/gift-vouchers`,
        title: "sidenav.giftVouchers",
        icon: "bi:gift",
        breadcrumb: false,
        submenu: [
          {
            key: "giftVouchersCampaign",
            path: `${APP_PREFIX_PATH}/gift-vouchers/campaign/list`,
            title: "sidenav.giftVouchers.campaign.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "giftVouchersReleaseList",
            path: `${APP_PREFIX_PATH}/gift-vouchers/release/list`,
            title: "sidenav.giftVouchers.release.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

// const settingNav = [
//   {
//     key: "setting",
//     path: `${APP_PREFIX_PATH}/setting`,
//     title: "sidenav.setting",
//     icon: SettingOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

const navigationConfig = [
  ...homeNav,
  // ...dashBoardNav,
  // ...promotionCampaignNav,
  // ...discountTreeNav,
  ...voucherTreeNav,
  // ...customerNav,
  ...partnerTreeNav,
  ...partnerClientTreeNav,
  ...gifVoucherTreeNav,
  // ...settingNav,
];

export default navigationConfig;
