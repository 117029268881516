import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import AmplifyService from "services/AmplifyService";
import {
  DELETE_IMPORT,
  FETCH_UPLOAD_ITEMS,
  RELEASE_IMPORT,
} from "../constants/UploadItemList";
import { get, isEmpty } from "lodash";
import {
  fetchUploadItems,
  fetchUploadItemsSuccess,
  fetchUploadItemsFalse,
} from "../actions/UploadItemsList";
import { CF_ENDPOINT_URL } from "configs/AppConfig";

export function* loadUploadItems() {
  yield takeEvery(FETCH_UPLOAD_ITEMS, function* ({ params }) {
    const { importId, pagination } = params;

    try {
      let url = `/campaigns/import-datas/list?importId=${importId}&page=${pagination.current}&limit=${pagination.pageSize}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "email") {
          orderBy = "email";
        } else if (params.sorter.field === "phone") {
          orderBy = "phone";
        } else {
          orderBy = "id";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGetUploadItems, url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchUploadItemsSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
            importTime: res.data.import.createdAt,
            totalImportItems: res.data.import.amount,
            totalInvalid: res.data.import.totalInvalid,
            fileName: res.data.import.fileName,
            s3Link: `${CF_ENDPOINT_URL}/public/${res.data.import.fileName}`,
            importStatus: res.data.import.status,
            campaignCode: res.data.campaignCode,
            campaignId: res.data.campaignId,
            campaignName: res.data.campaignName,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(fetchUploadItemsFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchUploadItemsFalse(get(err, "message", "Có lỗi xảy ra !")));
    }
  });
}

export function* releaseImport() {
  yield takeEvery(RELEASE_IMPORT, function* ({ params }) {
    const { importId, releasedBy } = params;

    try {
      const res = yield call(AmplifyService.releaseImport, {
        id: importId,
        releasedBy: releasedBy,
      });
      if (res.code === 200) {
        yield put(
          fetchUploadItems({
            importId: importId,
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
          })
        );
      } else {
        console.log("Release Import error", res);
        yield put(fetchUploadItemsFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchUploadItemsFalse(get(err, "message", "Có lỗi xảy ra !")));
    }
  });
}

export function* deleteImport() {
  yield takeEvery(DELETE_IMPORT, function* ({ params }) {
    const { importId } = params;

    try {
      const res = yield call(AmplifyService.deleteImportRelease(importId));
      if (res.code === 200) {
        const body = {
          importId: importId,
          pagination: {
            defaultPageSize: 10,
            total: 0,
            current: 1,
            pageSize: 10,
          },
        };
        yield put(fetchUploadItems, body);
      } else {
        console.log("Delete import error", res);
        yield put(fetchUploadItemsFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchUploadItemsFalse(get(err, "message", "Có lỗi xảy ra !")));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadUploadItems), fork(deleteImport), fork(releaseImport)]);
}
