import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNOUT,
  SIGNIN_WITH_COGNITO,
  SIGNIN_WITH_EMAIL,
  INPUT_NEW_PASSWORD,
} from "../constants/Auth";
import {
  showAuthMessage,
  signOutSuccess,
  signInCognitoAuthenticated,
  requiredNewPass,
  signInEmailAuthenticated,
} from "../actions/Auth";
import AmplifyService from "services/AmplifyService";

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess(""));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithCognito() {
  yield takeEvery(SIGNIN_WITH_COGNITO, function* ({ user }) {
    try {
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        const token = yield call(AmplifyService.refreshToken);

        localStorage.setItem(
          AUTH_TOKEN,
          JSON.stringify({ ...user.attributes, ...token.data })
        );
        yield put(
          signInCognitoAuthenticated({ ...user.attributes, ...token.data })
        );
      }
    } catch (error) {
      console.log("error", error);
      yield put(showAuthMessage(error));
    }
  });
}

export function* signOutWithCognito() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithEmail() {
  yield takeEvery(SIGNIN_WITH_EMAIL, function* ({ user }) {
    try {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        yield put(
          requiredNewPass({
            user: user,
            showInputNewPass: true,
            loading: false,
          })
        );
      } else {
        const token = yield call(AmplifyService.refreshToken);

        localStorage.setItem(
          AUTH_TOKEN,
          JSON.stringify({ ...user.attributes, ...token.data })
        );
        yield put(
          signInCognitoAuthenticated({ ...user.attributes, ...token.data })
        );
      }
    } catch (error) {
      console.log("error", error);
      yield put(showAuthMessage(error));
    }
  });
}

export function* inputNewPassword() {
  yield takeEvery(INPUT_NEW_PASSWORD, function* ({ user }) {
    try {
      const token = yield call(AmplifyService.refreshToken);

      localStorage.setItem(
        AUTH_TOKEN,
        JSON.stringify({ ...user.attributes, ...token.data })
      );
      yield put(
        signInEmailAuthenticated({
          token: { ...user.attributes, ...token.data },
          loading: false,
          showInputNewPass: false,
        })
      );
    } catch (error) {
      console.log("error", error);
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signOut),
    fork(signInWithCognito),
    fork(signInWithEmail),
    fork(inputNewPassword),
  ]);
}
