import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import AmplifyService from "services/AmplifyService";
import { FETCH_IMPORT_DATA } from "../constants/ImportRelease";
import { get, isEmpty } from "lodash";
import {
  fetchImportDataSuccess,
  fetchImportDataFalse,
} from "../actions/ImportRelease";

export function* loadImportData() {
  yield takeEvery(FETCH_IMPORT_DATA, function* ({ params }) {
    const { id, pagination, createdAt, status } = params;

    try {
      let url = `/campaigns/imports/list?campaignId=${id}&page=${pagination.current}&limit=${pagination.pageSize}`;

      if (status !== "" && status !== "all") url = `${url}&status=${status}`;
      if (createdAt !== "") url = `${url}&createdAt=${createdAt}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "fileName") {
          orderBy = "file_name";
        } else if (params.sorter.field === "createdAt") {
          orderBy = "created_at";
        } else {
          orderBy = "id";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGetImportRelease, url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchImportDataSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
            totalRemain: res.data.campaignRemainCount,
            totalImport: res.data.campaignImportTotal,
            campaignCode: res.data.campaignCode,
            campaignId: res.data.campaignId,
            campaignName: res.data.campaignName,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(fetchImportDataFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchImportDataFalse(get(err, "message", "Có lỗi xảy ra !")));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadImportData)]);
}
