export const FETCH_PARTNER_DATA = "FETCH_PARTNER_DATA";
export const FETCH_PARTNER_DATA_SUCCESS = "FETCH_PARTNER_DATA_SUCCESS";
export const FETCH_PARTNER_DATA_ERROR = "FETCH_PARTNER_DATA_ERROR";
export const ADD_NEW_PARTNER_SUCCESS = "ADD_NEW_PARTNER_SUCCESS";
export const ADD_NEW_PARTNER_FAIL = "ADD_NEW_PARTNER_FAIL";
export const ADD_NEW_PARTNER = "ADD_NEW_PARTNER";
export const FETCH_PARTNER_DATA_FALSE = "FETCH_PARTNER_DATA_FALSE";
export const FETCH_PARTNER_DATA_DETAIL = "FETCH_PARTNER_DATA_DETAIL";
export const FETCH_PARTNER_DETAIL_ERROR = "FETCH_PARTNER_DETAIL_ERROR";
export const FETCH_PARTNER_DETAI_SUCCESS = "FETCH_PARTNER_DETAI_SUCCESS";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const UPDATE_PARTNER_INFO_SUCCESS = "UPDATE_PARTNER_INFO_SUCCESS";
export const UPDATE_PARTNER_INFO_FAIL = "UPDATE_PARTNER_INFO_FAIL";
export const UPDATE_PARTNER_INFO = "UPDATE_PARTNER_INFO";
export const UPDATE_PARTNER_ACCOUNT_SUCCESS = "UPDATE_PARTNER_ACCOUNT_SUCCESS";
export const UPDATE_PARTNER_ACCOUNT_FAIL = "UPDATE_PARTNER_ACCOUNT_FAIL";
export const UPDATE_PARTNER_ACCOUNT = "UPDATE_PARTNER_ACCOUNT";
export const FETCH_PARTNER_CAMPAIGN_DATA = "FETCH_PARTNER_CAMPAIGN_DATA";
export const FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS =
  "FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS";
export const FETCH_PARTNER_CAMPAIGN_DATA_ERROR =
  "FETCH_PARTNER_CAMPAIGN_DATA_ERROR";
export const ADD_ERROR_MESSAGE_PARTNER_CAMPAIN =
  "ADD_ERROR_MESSAGE_PARTNER_CAMPAIN";
export const CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN =
  "CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN";
export const UPDATE_PARTNER_CAMPAIGN_DATA = "UPDATE_PARTNER_CAMPAIGN_DATA";
export const UPDATE_PARTNER_CAMPAIGN_DATA_SUCCESS =
  "UPDATE_PARTNER_CAMPAIGN_DATA_SUCCESS";
export const UPDATE_PARTNER_CAMPAIGN_DATA_ERROR =
  "UPDATE_PARTNER_CAMPAIGN_DATA_ERROR";
export const UPDATE_PARTNER_CAMPAIGN_STATUS = "UPDATE_PARTNER_CAMPAIGN_STATUS";
export const UPDATE_PARTNER_CAMPAIGN_STATUS_SUCCESS =
  "UPDATE_PARTNER_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR =
  "UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR";
export const DELETE_PARTNER_CAMPAIGN = "DELETE_PARTNER_CAMPAIGN";
export const DELETE_PARTNER_CAMPAIGN_SUCCESS =
  "DELETE_PARTNER_CAMPAIGN_SUCCESS";
export const DELETE_PARTNER_CAMPAIGN_ERROR = "DELETE_PARTNER_CAMPAIGN_ERROR";
export const UPLOAD_CAMPAIGN_IMAGE = "UPLOAD_CAMPAIGN_IMAGE";
export const UPLOAD_CAMPAIGN_IMAGE_SUCCESS = "UPLOAD_CAMPAIGN_IMAGE_SUCCESS";
export const UPLOAD_CAMPAIGN_IMAGE_ERROR = "UPLOAD_CAMPAIGN_IMAGE_ERROR";
export const SET_LOADING = "SET_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const ADD_NEW_PARTNER_CAMPAIGN_SUCCESS =
  "ADD_NEW_PARTNER_CAMPAIGN_SUCCESS";
export const ADD_NEW_PARTNER_CAMPAIGN_FAIL = "ADD_NEW_PARTNER_CAMPAIGN_FAIL";
export const ADD_NEW_PARTNER_CAMPAIGN = "ADD_NEW_PARTNER_CAMPAIGN";
export const FETCH_FULL_PARTNER_DATA = "FETCH_FULL_PARTNER_DATA";
export const FETCH_FULL_PARTNER_DATA_SUCCESS =
  "FETCH_FULL_PARTNER_DATA_SUCCESS";
export const FETCH_FULL_PARTNER_DATA_ERROR = "FETCH_FULL_PARTNER_DATA_ERROR";
export const FETCH_PARTNER_CAMPAIGN_DETAIL = "FETCH_PARTNER_CAMPAIGN_DETAIL";
export const FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR =
  "FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR";
export const FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS =
  "FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS";
