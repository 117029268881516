import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  FETCH_PARTNER_RELEASE_VOUCHER_DATA,
  FETCH_RELEASE_VOUCHER_DATA,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA,
} from "../constants/ReleaseVoucher";
import {
  fetchReleaseVoucherDataSuccess,
  fetchReleaseVoucherDataFalse,
  fetchPartnerReleaseVoucherDataSuccess,
  fetchPartnerReleaseVoucherDataFalse,
  fetchPartnerClientReleaseVoucherDataSuccess,
  fetchPartnerClientReleaseVoucherDataFalse,
  fetchGiftVoucherReleaseVoucherDataFalse,
  fetchGiftVoucherReleaseVoucherDataSuccess,
} from "../actions/ReleaseVocher";

import AmplifyService from "services/AmplifyService";
import { get, isEmpty } from "lodash";
import moment from "moment";

export function* loadReleaseVoucher() {
  yield takeEvery(FETCH_RELEASE_VOUCHER_DATA, function* ({ params }) {
    const {
      campaignCode,
      keywords,
      status,
      createdDate,
      receiveDate,
      useDate,
      pagination,
      _function,
    } = params;
    try {
      let url = `/releases/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=${_function}`;

      if (campaignCode !== "") {
        url = `/releases/list?campaignCode=${campaignCode}&page=${pagination.current}&limit=${pagination.pageSize}&prefix=${_function}`;
      }

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (status !== "" && status !== "all" && status !== undefined)
        url = `${url}&status=${status}`;
      if (createdDate !== "")
        url = `${url}&createdAt=${moment(createdDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;
      if (receiveDate !== "")
        url = `${url}&receiveAt=${moment(receiveDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;
      if (useDate !== "")
        url = `${url}&useAt=${moment(useDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "code") {
          orderBy = "code";
        } else if (params.sorter.field === "expireDate") {
          orderBy = "expiry_at";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        } else if (params.sorter.field === "receiveDate") {
          orderBy = "receive_at";
        } else if (params.sorter.field === "useDate") {
          orderBy = "use_at";
        } else {
          orderBy = "id";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      }

      const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchReleaseVoucherDataSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);

        yield put(fetchReleaseVoucherDataFalse());
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchReleaseVoucherDataFalse());
    }
  });
}

export function* loadPartnerReleaseVoucher() {
  yield takeEvery(FETCH_PARTNER_RELEASE_VOUCHER_DATA, function* ({ params }) {
    const {
      campaignCode,
      keywords,
      status,
      createdDate,
      receiveDate,
      useDate,
      pagination,
      channel,
    } = params;
    try {
      let url = `/releases/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=partner`;
      if (campaignCode !== "") {
        url = `/releases/list?campaignCode=${campaignCode}&page=${pagination.current}&limit=${pagination.pageSize}&prefix=partner`;
      }

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (status !== "" && status !== "all" && status !== undefined)
        url = `${url}&status=${status}`;
      if (createdDate !== "")
        url = `${url}&createdAt=${moment(createdDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;
      if (receiveDate !== "")
        url = `${url}&receiveAt=${moment(receiveDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;
      if (useDate !== "")
        url = `${url}&useAt=${moment(useDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )}`;
      if (channel !== "" && channel !== undefined)
        url = `${url}&channel=${channel}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "code") {
          orderBy = "code";
        } else if (params.sorter.field === "expireDate") {
          orderBy = "expiry_at";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        } else if (params.sorter.field === "receiveDate") {
          orderBy = "receive_at";
        } else if (params.sorter.field === "useDate") {
          orderBy = "use_at";
        } else if (params.sorter.field === "channel") {
          orderBy = "channel";
        } else if (params.sorter.field === "invoiceCode") {
          orderBy = "invoiceCode";
        } else {
          orderBy = "id";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      }

      const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchPartnerReleaseVoucherDataSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);

        yield put(fetchPartnerReleaseVoucherDataFalse());
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchPartnerReleaseVoucherDataFalse());
    }
  });
}

export function* loadPartnerClientReleaseVoucher() {
  yield takeEvery(
    FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA,
    function* ({ params }) {
      const {
        campaignCode,
        keywords,
        status,
        createdDate,
        receiveDate,
        useDate,
        pagination,
        channel,
      } = params;
      try {
        let url = `/releases/client/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=partner`;
        if (campaignCode !== "") {
          url = `/releases/client/list?campaignCode=${campaignCode}&page=${pagination.current}&limit=${pagination.pageSize}&prefix=partner`;
        }

        if (keywords !== "") url = `${url}&keywords=${keywords}`;
        if (status !== "" && status !== "all" && status !== undefined)
          url = `${url}&status=${status}`;
        if (createdDate !== "")
          url = `${url}&createdAt=${moment(createdDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (receiveDate !== "")
          url = `${url}&receiveAt=${moment(receiveDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (useDate !== "")
          url = `${url}&useAt=${moment(useDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (channel !== "" && channel !== undefined)
          url = `${url}&channel=${channel}`;

        if (!isEmpty(get(params, "sorter", {}))) {
          let orderBy = params.sorter.field;
          if (params.sorter.field === "code") {
            orderBy = "code";
          } else if (params.sorter.field === "expireDate") {
            orderBy = "expiry_at";
          } else if (params.sorter.field === "createdDate") {
            orderBy = "created_at";
          } else if (params.sorter.field === "receiveDate") {
            orderBy = "receive_at";
          } else if (params.sorter.field === "useDate") {
            orderBy = "use_at";
          } else if (params.sorter.field === "channel") {
            orderBy = "channel";
          } else if (params.sorter.field === "invoiceCode") {
            orderBy = "invoiceCode";
          } else {
            orderBy = "id";
          }

          url = `${url}&orderBy=${orderBy}&orderType=${
            params.sorter.order === "ascend" ? "asc" : "desc"
          }`;
        }

        const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
        if (res.code === 200) {
          const returnPagination = { ...pagination };

          returnPagination.total = res.data.total;
          yield put(
            fetchPartnerClientReleaseVoucherDataSuccess({
              items: res.data.items,
              currentPage: res.data.currentPage,
              pagination: returnPagination,
            })
          );
        } else {
          console.log("Load data error", res);

          yield put(fetchPartnerClientReleaseVoucherDataFalse());
        }
      } catch (err) {
        console.log("===ERROR===", err);
        yield put(fetchPartnerClientReleaseVoucherDataFalse());
      }
    }
  );
}

export function* loadGiftVoucherReleaseVoucher() {
  yield takeEvery(
    FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA,
    function* ({ params }) {
      const {
        campaignCode,
        keywords,
        status,
        createdDate,
        receiveDate,
        useDate,
        pagination,
        channel,
      } = params;
      try {
        let url = `/releases/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=giftvoucher`;
        if (campaignCode !== "") {
          url = `/releases/list?campaignCode=${campaignCode}&page=${pagination.current}&limit=${pagination.pageSize}&prefix=giftvoucher`;
        }

        if (keywords !== "") url = `${url}&keywords=${keywords}`;
        if (status !== "" && status !== "all" && status !== undefined)
          url = `${url}&status=${status}`;
        if (createdDate !== "")
          url = `${url}&createdAt=${moment(createdDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (receiveDate !== "")
          url = `${url}&receiveAt=${moment(receiveDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (useDate !== "")
          url = `${url}&useAt=${moment(useDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )}`;
        if (channel !== "" && channel !== undefined)
          url = `${url}&channel=${channel}`;

        if (!isEmpty(get(params, "sorter", {}))) {
          let orderBy = params.sorter.field;
          if (params.sorter.field === "code") {
            orderBy = "code";
          } else if (params.sorter.field === "expireDate") {
            orderBy = "expiry_at";
          } else if (params.sorter.field === "createdDate") {
            orderBy = "created_at";
          } else if (params.sorter.field === "receiveDate") {
            orderBy = "receive_at";
          } else if (params.sorter.field === "useDate") {
            orderBy = "use_at";
          } else if (params.sorter.field === "channel") {
            orderBy = "channel";
          } else if (params.sorter.field === "invoiceCode") {
            orderBy = "invoiceCode";
          } else {
            orderBy = "id";
          }

          url = `${url}&orderBy=${orderBy}&orderType=${
            params.sorter.order === "ascend" ? "asc" : "desc"
          }`;
        }

        const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
        if (res.code === 200) {
          const returnPagination = { ...pagination };

          returnPagination.total = res.data.total;
          yield put(
            fetchGiftVoucherReleaseVoucherDataSuccess({
              items: res.data.items,
              currentPage: res.data.currentPage,
              pagination: returnPagination,
            })
          );
        } else {
          console.log("Load data error", res);

          yield put(fetchGiftVoucherReleaseVoucherDataFalse());
        }
      } catch (err) {
        console.log("===ERROR===", err);
        yield put(fetchGiftVoucherReleaseVoucherDataFalse());
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(loadReleaseVoucher),
    fork(loadPartnerReleaseVoucher),
    fork(loadGiftVoucherReleaseVoucher),
    fork(loadPartnerClientReleaseVoucher),
  ]);
}
