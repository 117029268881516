import {
  FETCH_PARTNER_DATA,
  FETCH_PARTNER_DATA_SUCCESS,
  FETCH_PARTNER_DATA_FALSE,
  ADD_NEW_PARTNER,
  ADD_NEW_PARTNER_FAIL,
  ADD_NEW_PARTNER_SUCCESS,
  CLEAR_ERROR_MESSAGE,
  UPDATE_PARTNER_INFO,
  UPDATE_PARTNER_INFO_FAIL,
  UPDATE_PARTNER_INFO_SUCCESS,
  UPDATE_PARTNER_ACCOUNT,
  UPDATE_PARTNER_ACCOUNT_FAIL,
  UPDATE_PARTNER_ACCOUNT_SUCCESS,
  FETCH_PARTNER_CAMPAIGN_DATA,
  FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS,
  FETCH_PARTNER_CAMPAIGN_DATA_ERROR,
  CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN,
  UPDATE_PARTNER_CAMPAIGN_DATA,
  UPDATE_PARTNER_CAMPAIGN_DATA_SUCCESS,
  UPDATE_PARTNER_CAMPAIGN_DATA_ERROR,
  UPDATE_PARTNER_CAMPAIGN_STATUS,
  UPDATE_PARTNER_CAMPAIGN_STATUS_SUCCESS,
  UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR,
  DELETE_PARTNER_CAMPAIGN,
  DELETE_PARTNER_CAMPAIGN_SUCCESS,
  DELETE_PARTNER_CAMPAIGN_ERROR,
  UPLOAD_CAMPAIGN_IMAGE,
  UPLOAD_CAMPAIGN_IMAGE_ERROR,
  UPLOAD_CAMPAIGN_IMAGE_SUCCESS,
  SET_LOADING,
  STOP_LOADING,
  ADD_ERROR_MESSAGE_PARTNER_CAMPAIN,
  ADD_NEW_PARTNER_CAMPAIGN,
  ADD_NEW_PARTNER_CAMPAIGN_FAIL,
  ADD_NEW_PARTNER_CAMPAIGN_SUCCESS,
  FETCH_FULL_PARTNER_DATA,
  FETCH_FULL_PARTNER_DATA_SUCCESS,
  FETCH_FULL_PARTNER_DATA_ERROR,
  FETCH_PARTNER_CAMPAIGN_DETAIL,
  FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS,
  FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR,
} from "../constants/Partnership";

export function fetchPartnerData(params) {
  return {
    type: FETCH_PARTNER_DATA,
    params: params,
  };
}
export function fetchPartnerDataSuccess(data) {
  return {
    type: FETCH_PARTNER_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchPartnerDataFalse(err) {
  return {
    type: FETCH_PARTNER_DATA_FALSE,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addPartner(params) {
  return {
    type: ADD_NEW_PARTNER,
    params: params,
  };
}

export function addPartnerFail(err) {
  return {
    type: ADD_NEW_PARTNER_FAIL,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addPartnerSuccess(data) {
  return {
    type: ADD_NEW_PARTNER_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function clearErrMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
    data: {
      errMessage: "",
      loading: false,
    },
  };
}

export function updatePartnerInfo(params) {
  return {
    type: UPDATE_PARTNER_INFO,
    params: params,
  };
}

export function updatePartnerInfoFail(err) {
  return {
    type: UPDATE_PARTNER_INFO_FAIL,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function updatePartnerInfoSuccess(data) {
  return {
    type: UPDATE_PARTNER_INFO_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function updatePartnerAccount(params) {
  return {
    type: UPDATE_PARTNER_ACCOUNT,
    params: params,
  };
}

export function updatePartnerAccountFail(err) {
  return {
    type: UPDATE_PARTNER_ACCOUNT_FAIL,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function updatePartnerAccountSuccess(data) {
  return {
    type: UPDATE_PARTNER_ACCOUNT_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function fetchPartnerCampaignData(params) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DATA,
    params: params,
  };
}
export function fetchPartnerCampaignDataSuccess(data) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchPartnerCampaignDataFalse(err) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DATA_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addErrMessagePartnerCampain(err) {
  return {
    type: ADD_ERROR_MESSAGE_PARTNER_CAMPAIN,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function clearErrMessagePartnerCampain() {
  return {
    type: CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN,
    data: {
      errMessage: "",
      message: "",
      loading: false,
    },
  };
}

export function updatePartnerCampaign(params) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_DATA,
    params: params,
  };
}

export function updatePartnerCampaignFail(err) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_DATA_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function updatePartnerCampaignSuccess(data) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_DATA_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function updatePartnerCampaignStatus(params) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_STATUS,
    params: params,
  };
}

export function updatePartnerCampaignStatusFail(err) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function updatePartnerCampaignStatusSuccess(data) {
  return {
    type: UPDATE_PARTNER_CAMPAIGN_STATUS_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function deletePartnerCampaign(params) {
  return {
    type: DELETE_PARTNER_CAMPAIGN,
    params: params,
  };
}

export function deletePartnerCampaignFail(err) {
  return {
    type: DELETE_PARTNER_CAMPAIGN_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function deletePartnerCampaignSuccess(data) {
  return {
    type: DELETE_PARTNER_CAMPAIGN_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function uploadCampaignImage(params) {
  return {
    type: UPLOAD_CAMPAIGN_IMAGE,
    params: params,
  };
}

export function uploadCampaignImageFail(err) {
  return {
    type: UPLOAD_CAMPAIGN_IMAGE_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function uploadCampaignImageSuccess(data) {
  return {
    type: UPLOAD_CAMPAIGN_IMAGE_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function setLoading() {
  return {
    type: SET_LOADING,
    data: {
      loading: true,
    },
  };
}

export function stopLoading() {
  return {
    type: STOP_LOADING,
    data: {
      loading: false,
    },
  };
}

export function addPartnerCampaign(params) {
  return {
    type: ADD_NEW_PARTNER_CAMPAIGN,
    params: params,
  };
}

export function addPartnerCampaignFail(err) {
  return {
    type: ADD_NEW_PARTNER_CAMPAIGN_FAIL,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addPartnerCampaignSuccess(message) {
  return {
    type: ADD_NEW_PARTNER_CAMPAIGN_SUCCESS,
    data: {
      message: message,
      loading: false,
    },
  };
}

export function fetchFullPartnerData() {
  return {
    type: FETCH_FULL_PARTNER_DATA,
  };
}
export function fetchFullPartnerDataSuccess(data) {
  return {
    type: FETCH_FULL_PARTNER_DATA_SUCCESS,
    data: {
      allPartner: data,
    },
  };
}

export function fetchFullPartnerDataFalse(err) {
  return {
    type: FETCH_FULL_PARTNER_DATA_ERROR,
    data: {
      errMessage: err,
    },
  };
}

export function fetchCampaignDetail(params) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DETAIL,
    params: params,
  };
}
export function fetchCampaignDetailSuccess(data) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS,
    data: {
      campaignDetail: data,
    },
  };
}

export function fetchCampaignDetailFalse(err) {
  return {
    type: FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR,
    data: {
      errMessage: err,
    },
  };
}
