export const FETCH_UPLOAD_ITEMS = "FETCH_UPLOAD_ITEMS";
export const FETCH_UPLOAD_ITEMS_SUCCESS = "FETCH_UPLOAD_ITEMS_SUCCESS";
export const FETCH_UPLOAD_ITEMS_ERROR = "FETCH_UPLOAD_ITEMS_ERROR";
export const CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE =
  "CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE";
export const RELEASE_IMPORT = "RELEASE_IMPORT";
export const RELEASE_IMPORT_SUCCESS = "RELEASE_IMPORT_SUCCESS";
export const RELEASE_IMPORT_ERROR = "RELEASE_IMPORT_ERROR";
export const DELETE_IMPORT = "DELETE_IMPORT";
export const DELETE_IMPORT_SUCCESS = "DELETE_IMPORT_SUCCESS";
export const DELETE_IMPORT_ERROR = "DELETE_IMPORT_ERROR";
