import {
  ADD_NEW_SUCCESS,
  FETCH_DATA_SUCCESS,
  FETCH_DATA,
  FETCH_DATA_FALSE,
  FETCH_DATA_DETAIL,
  FETCH_DATA_DETAI_SUCCESS,
  FETCH_DATA_DETAIL_ERROR,
} from "../constants/Voucher";

export function fetchData(params) {
  return {
    type: FETCH_DATA,
    params: params,
  };
}
export function fetchDataSuccess(data) {
  return {
    type: FETCH_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchDataFalse(err) {
  return {
    type: FETCH_DATA_FALSE,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addVoucher() {
  return {
    type: ADD_NEW_SUCCESS,
    data: {
      isReload: true,
    },
  };
}

export function fetchDataDetail(id) {
  return {
    type: FETCH_DATA_DETAIL,
    id: id,
  };
}
export function fetchDataDetailSuccess(voucherDetail) {
  return {
    type: FETCH_DATA_DETAI_SUCCESS,
    data: { voucherDetail: voucherDetail },
  };
}

export function fetchDataDetailFalse() {
  return {
    type: FETCH_DATA_DETAIL_ERROR,
  };
}
