import React from "react";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import AmplifyService from "../../services/AmplifyService";
import { useDispatch } from "react-redux";
import { showLoading } from "../../redux/actions/Auth";

const NavProfile = () => {
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    dispatch(showLoading());
    // await AmplifyService.deleteToken().catch((err) => {
    //   console.log("deleteTokenErr", err.message);
    // });
    await AmplifyService.signOutRequest().catch((err) => {
      console.log("signOutRequestErr", err.message);
    });
  };

  return (
    <Menu className="d-flex align-item-center" mode="horizontal">
      <Menu.Item onClick={handleSignOut} key="logout">
        <span>
          <LogoutOutlined />
        </span>
      </Menu.Item>
    </Menu>
  );
};

export default NavProfile;
