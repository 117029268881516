import {
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA,
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS,
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR,
  CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS,
  DELETE_GIFT_VOUCHER_CAMPAIGN,
  DELETE_GIFT_VOUCHER_CAMPAIGN_ERROR,
  DELETE_GIFT_VOUCHER_CAMPAIGN_SUCCESS,
  GIFT_VOUCHER_SET_LOADING,
  GIFT_VOUCHER_STOP_LOADING,
  ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR,
} from "../constants/GiftVoucher";

export function fetchGiftVoucherCampaignData(params) {
  return {
    type: FETCH_GIFT_VOUCHER_CAMPAIGN_DATA,
    params: params,
  };
}
export function fetchGiftVoucherCampaignDataSuccess(data) {
  return {
    type: FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchGiftVoucherCampaignDataFalse(err) {
  return {
    type: FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addErrMessageGiftVoucherCampain(err) {
  return {
    type: ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function clearErrMessageGiftVoucherCampain() {
  return {
    type: CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
    data: {
      errMessage: "",
      message: "",
      loading: false,
    },
  };
}

export function updateGiftVoucherCampaignStatus(params) {
  return {
    type: UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS,
    params: params,
  };
}

export function updateGiftVoucherCampaignStatusFail(err) {
  return {
    type: UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function updateGiftVoucherCampaignStatusSuccess(data) {
  return {
    type: UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function deleteGiftVoucherCampaign(params) {
  return {
    type: DELETE_GIFT_VOUCHER_CAMPAIGN,
    params: params,
  };
}

export function deleteGiftVoucherCampaignFail(err) {
  return {
    type: DELETE_GIFT_VOUCHER_CAMPAIGN_ERROR,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function deleteGiftVoucherCampaignSuccess(data) {
  return {
    type: DELETE_GIFT_VOUCHER_CAMPAIGN_SUCCESS,
    data: {
      loading: false,
    },
  };
}

export function giftVoucherSetLoading() {
  return {
    type: GIFT_VOUCHER_SET_LOADING,
    data: {
      loading: true,
    },
  };
}

export function giftVoucherStopLoading() {
  return {
    type: GIFT_VOUCHER_STOP_LOADING,
    data: {
      loading: false,
    },
  };
}

export function addGiftVoucherCampaign(params) {
  return {
    type: ADD_NEW_GIFT_VOUCHER_CAMPAIGN,
    params: params,
  };
}

export function addGiftVoucherCampaignFail(err) {
  return {
    type: ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL,
    data: {
      errMessage: err,
      loading: false,
    },
  };
}

export function addGiftVoucherCampaignSuccess(message) {
  return {
    type: ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS,
    data: {
      message: message,
      loading: false,
    },
  };
}

export function fetchFullGiftVoucherPartnerData() {
  return {
    type: FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA,
  };
}
export function fetchFullGiftVoucherPartnerDataSuccess(data) {
  return {
    type: FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS,
    data: {
      allPartner: data,
    },
  };
}

export function fetchFullGiftVoucherPartnerDataFalse(err) {
  return {
    type: FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR,
    data: {
      errMessage: err,
    },
  };
}
