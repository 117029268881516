/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:2ad1f7d4-7bf9-4a0e-ad53-aeb816434318",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_lb6st2S5n",
    "aws_user_pools_web_client_id": "63um5ktjj7jle45ooj9m4f0u5c",
    "oauth": {
        "domain": "pnjstg-mpos.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://loyalty.stage.pnj.io/",
        "redirectSignOut": "https://loyalty.stage.pnj.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pnj-loyalty-img-stg",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "pnjLoyalty",
            "endpoint": "https://lzhoyoli40.execute-api.ap-southeast-1.amazonaws.com/stage",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
