import {
  IMPORT_RELEASE_SET_LOADING,
  IMPORT_RELEASE_STOP_LOADING,
  FETCH_IMPORT_DATA,
  FETCH_IMPORT_DATA_SUCCESS,
  FETCH_IMPORT_DATA_FALSE,
  CLEAR_IMPORT_DATA_ERROR_MESSAGE,
} from "../constants/ImportRelease";

const initImportRelease = {
  loading: false,
  err: "",
  items: [],
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
  totalRemain: 0,
  totalImport: 0,
};

const importRelease = (state = initImportRelease, action) => {
  switch (action.type) {
    case FETCH_IMPORT_DATA:
    case IMPORT_RELEASE_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_RELEASE_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_IMPORT_DATA_ERROR_MESSAGE:
    case FETCH_IMPORT_DATA_FALSE:
    case FETCH_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default importRelease;
