import {
  ADD_NEW_SUCCESS,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FALSE,
  FETCH_DATA,
  FETCH_DATA_DETAIL,
  FETCH_DATA_DETAI_SUCCESS,
} from "../constants/Voucher";

const initVoucher = {
  items: [],
  currentPage: 1,
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
  isReload: false,
  voucherDetail: {},
  err: "",
};

const voucher = (state = initVoucher, action) => {
  switch (action.type) {
    case FETCH_DATA:
    case FETCH_DATA_DETAIL:
      return {
        ...state,
        ...{ loading: true },
      };
    case FETCH_DATA_DETAI_SUCCESS:
    case FETCH_DATA_SUCCESS:
    case ADD_NEW_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_DATA_FALSE:
      return {
        ...state,
        ...{
          items: [],
          currentPage: 1,
          pagination: {
            defaultPageSize: 10,
            total: 0,
            current: 1,
            pageSize: 10,
          },
          isReload: false,
        },
        ...action.data,
      };
    default:
      return state;
  }
};

export default voucher;
