import {
  FETCH_RELEASE_VOUCHER_DATA,
  FETCH_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE,
} from "../constants/ReleaseVoucher";

const initVoucher = {
  items: [],
  currentPage: 1,
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
  isReload: false,
  voucherDetail: {},
  err: "",
};

const voucher = (state = initVoucher, action) => {
  switch (action.type) {
    case FETCH_PARTNER_RELEASE_VOUCHER_DATA:
    case FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA:
    case FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA:
    case FETCH_RELEASE_VOUCHER_DATA:
      return {
        ...state,
        ...{ loading: true },
      };
    case FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS:
    case FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS:
    case FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS:
    case FETCH_RELEASE_VOUCHER_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE:
    case FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE:
    case FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE:
    case FETCH_RELEASE_VOUCHER_DATA_FALSE:
      return {
        ...state,
        ...{
          items: [],
          currentPage: 1,
          pagination: {
            defaultPageSize: 10,
            total: 0,
            current: 1,
            pageSize: 10,
          },
          isReload: false,
          err: "Loading fail",
        },
      };
    default:
      return state;
  }
};

export default voucher;
