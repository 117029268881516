import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_NEW_PARTNER,
  FETCH_PARTNER_DATA,
  UPDATE_PARTNER_ACCOUNT,
  UPDATE_PARTNER_INFO,
  FETCH_PARTNER_CAMPAIGN_DATA,
  UPDATE_PARTNER_CAMPAIGN_STATUS,
  DELETE_PARTNER_CAMPAIGN,
  UPLOAD_CAMPAIGN_IMAGE,
  ADD_NEW_PARTNER_CAMPAIGN,
  FETCH_FULL_PARTNER_DATA,
  FETCH_PARTNER_CAMPAIGN_DETAIL,
} from "../constants/Partnership";
import {
  fetchPartnerData,
  fetchPartnerDataSuccess,
  fetchPartnerDataFalse,
  addPartnerFail,
  fetchPartnerCampaignData,
  fetchPartnerCampaignDataSuccess,
  fetchPartnerCampaignDataFalse,
  updatePartnerCampaignStatusFail,
  deletePartnerCampaignFail,
  uploadCampaignImageFail,
  uploadCampaignImageSuccess,
  addPartnerCampaignFail,
  addPartnerCampaignSuccess,
  fetchFullPartnerDataSuccess,
  fetchFullPartnerDataFalse,
  fetchCampaignDetailSuccess,
  fetchCampaignDetailFalse,
} from "../actions/Partnership";

import AmplifyService from "services/AmplifyService";
import { get, isEmpty } from "lodash";

export function* loadPartnershipData() {
  yield takeEvery(FETCH_PARTNER_DATA, function* ({ params }) {
    const { keywords, createdAt, pagination } = params;

    try {
      let url = `/partners/list?page=${pagination.current}&limit=${pagination.pageSize}`;

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (createdAt !== "") url = `${url}&createdAt=${createdAt}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "name") {
          orderBy = "name";
        } else if (params.sorter.field === "code") {
          orderBy = "code";
        } else if (params.sorter.field === "summary") {
          orderBy = "summary";
        } else if (params.sorter.field === "startCampaignCount") {
          orderBy = "start_campaign_count";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        } else if (params.sorter.field === "updatedDate") {
          orderBy = "updated_at";
        } else {
          orderBy = "id";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGetPartnership, url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchPartnerDataSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(fetchPartnerDataFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchPartnerDataFalse(get(err, "message", "Có lỗi xảy ra !")));
    }
  });
}

export function* loadFullPartnershipData() {
  yield takeEvery(FETCH_FULL_PARTNER_DATA, function* () {
    try {
      let url = `/partners/list?page=1&limit=500`;

      const res = yield call(AmplifyService.apiGetPartnership, url);
      if (res.code === 200) {
        yield put(fetchFullPartnerDataSuccess(res.data.items));
      } else {
        console.log("Load data error", res);
        yield put(fetchFullPartnerDataFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(
        fetchFullPartnerDataFalse(get(err, "message", "Có lỗi xảy ra !"))
      );
    }
  });
}

export function* addNewPartner() {
  yield takeEvery(ADD_NEW_PARTNER, function* ({ params }) {
    const {
      fullName,
      code,
      address,
      phone,
      email,
      password,
      createdBy,
      summary,
      website,
      sapVendor,
    } = params;

    try {
      const body = {
        name: fullName ?? "",
        code: code ?? "",
        sapVendor: sapVendor ?? null,
        summary: summary ?? "",
        email: email ?? "",
        password: password ?? "",
        address: address ?? "",
        website: website ?? "",
        phone: phone ?? "",
        createdBy: createdBy ?? "",
      };

      const res = yield call(AmplifyService.addPartner, body);
      if (res.code === 200) {
        yield put(
          fetchPartnerData({
            keywords: "",
            createdAt: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
          })
        );
      } else {
        console.log("Add partner error", res);
        yield put(addPartnerFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(addPartnerFail(error.message ?? "Có lỗi xảy ra !"));
    }
  });
}

export function* updatePartnerInfo() {
  yield takeEvery(UPDATE_PARTNER_INFO, function* ({ params }) {
    const { id, fullName, code, address, phone, updatedBy, sapVendor } = params;

    try {
      const body = {
        id: id,
        name: fullName ?? "",
        code: code ?? "",
        address: address ?? "",
        phone: phone ?? "",
        updatedBy: updatedBy ?? "",
        sapVendor: sapVendor ?? null,
      };

      const res = yield call(AmplifyService.updatePartnerInfo, body);
      if (res.code === 200) {
        yield put(
          fetchPartnerData({
            keywords: "",
            createdAt: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            sorter: {
              field: "updatedAt",
              order: "desc",
            },
          })
        );
      } else {
        console.log("Update partner info error", res);
        yield put(addPartnerFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(addPartnerFail(error.message ?? "Có lỗi xảy ra !"));
    }
  });
}

export function* updatePartnerAccount() {
  yield takeEvery(UPDATE_PARTNER_ACCOUNT, function* ({ params }) {
    const { id, email, password, updatedBy } = params;

    try {
      const body = {
        id: id,
        email: email,
        password: password,
        updatedBy: updatedBy,
      };

      const res = yield call(AmplifyService.updatePartnerAccount, body);
      if (res.code === 200) {
        yield put(
          fetchPartnerData({
            keywords: "",
            createdAt: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            sorter: {
              field: "updatedDate",
              order: "desc",
            },
          })
        );
      } else {
        console.log("Update partner account error", res);
        yield put(addPartnerFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(addPartnerFail(error.message ?? "Có lỗi xảy ra !"));
    }
  });
}

export function* loadPartnershipCampaignData() {
  yield takeEvery(FETCH_PARTNER_CAMPAIGN_DATA, function* ({ params }) {
    const { keywords, createdAt, pagination, status } = params;

    try {
      let url = `/campaigns/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=partner`;

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (status !== "" && status !== "all" && status !== undefined)
        url = `${url}&status=${status}`;
      if (createdAt !== "") url = `${url}&createdAt=${createdAt}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "campaignCode") {
          orderBy = "code";
        } else if (params.sorter.field === "campaignName") {
          orderBy = "name";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        } else if (params.sorter.field === "partnerName") {
          orderBy = "partner_name";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGetPartnershipCampaign, url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total ?? 0;
        yield put(
          fetchPartnerCampaignDataSuccess({
            items: res.data.items ?? [],
            currentPage: res.data.currentPage ?? 1,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(fetchPartnerCampaignDataFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(
        fetchPartnerCampaignDataFalse(get(err, "message", "Có lỗi xảy ra !"))
      );
    }
  });
}

export function* updatePartnerCampaignStatus() {
  yield takeEvery(UPDATE_PARTNER_CAMPAIGN_STATUS, function* ({ params }) {
    const { id, updatedBy, status, _function } = params;

    try {
      const body = {
        id: id,
        status: status,
        updatedBy: updatedBy,
        // _function: _function,
      };

      const res = yield call(
        AmplifyService.updateStatusVoucherById,
        "pnjLoyalty",
        "/campaigns/update-status",
        body
      );
      if (res.code === 200) {
        yield put(
          fetchPartnerCampaignData({
            keywords: "",
            createdAt: "",
            status: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            // sorter: {
            //   field: "updatedDate",
            //   order: "desc",
            // },
          })
        );
      } else {
        console.log("Update partner campaign status error", res);
        yield put(
          updatePartnerCampaignStatusFail(get(res, "data.error.0", ""))
        );
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(
        updatePartnerCampaignStatusFail(
          get(error, "message", "Có lỗi xảy ra !")
        )
      );
    }
  });
}

export function* deletePartnerCampaign() {
  yield takeEvery(DELETE_PARTNER_CAMPAIGN, function* ({ params }) {
    const { id } = params;

    try {
      const url = `/campaigns/delete?id=${id}`;
      const res = yield call(AmplifyService.deleteVoucher, "pnjLoyalty", url);

      if (res.code === 200) {
        yield put(
          fetchPartnerCampaignData({
            keywords: "",
            createdAt: "",
            status: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            sorter: {
              field: "id",
              order: "desc",
            },
          })
        );
      } else {
        console.log("delete partner campaign error", res);
        yield put(deletePartnerCampaignFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(
        deletePartnerCampaignFail(get(error, "message", "Có lỗi xảy ra !"))
      );
    }
  });
}

export function* uploadCampaignImage() {
  yield takeEvery(UPLOAD_CAMPAIGN_IMAGE, function* ({ params }) {
    const { info } = params;
    try {
      const res = yield call(AmplifyService.uploadFileToS3, info.file);
      if (res.code === 200) {
        yield put(uploadCampaignImageSuccess, {
          ...res,
          ...{ uid: info.file.uid },
        });
      } else {
        console.log("Upload err", res.message);
        yield put(
          uploadCampaignImageFail,
          get(res.message, "message", "Có lỗi xảy ra !")
        );
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(
        uploadCampaignImageFail,
        get(error, "message", "Có lỗi xảy ra !")
      );
    }
  });
}

export function* addNewPartnerCampaign() {
  yield takeEvery(ADD_NEW_PARTNER_CAMPAIGN, function* ({ params }) {
    const {
      partnerId,
      campaignName,
      campaignCode,
      startAt,
      endAt,
      timeRemaining,
      description,
      type,
      limit,
      mediaS3,
      value,
      price,
      percent,
      createdBy,
      applyChannel,
      accountingType,
      totalFrom,
      totalTo,
      commonCode,
      limitUse,
      note,
      maxValue,
    } = params;

    try {
      let body = {
        prefix: "partner",
        partnerId: partnerId ?? null,
        name: campaignName ?? "",
        code: campaignCode ?? "",
        startAt: startAt ?? null,
        endAt: endAt ?? null,
        timeRemaining: timeRemaining ?? null,
        description: description ?? "",
        type: type ?? "",
        limit: limit ?? null,
        categoryId: null,
        mediaS3: mediaS3 ?? [],
        value: value ?? null,
        price: price ?? null,
        percent: percent ?? 0,
        createdBy: createdBy ?? "",
        applyChannel: applyChannel ?? null,
        accountingType: accountingType ?? "",
        totalFrom: totalFrom ?? 0,
        totalTo: totalTo ?? 0,
        commonCode: commonCode ?? false,
        note: note ?? "",
      };

      if (commonCode) {
        body["limitUse"] = limitUse;
      }

      if (type === "coupon" && maxValue > 0) {
        body["maxval"] = maxValue;
      }

      const res = yield call(AmplifyService.addPartnerCampaign, body);
      if (res.code === 200) {
        yield put(addPartnerCampaignSuccess("Add new campaign success!"));
      } else {
        console.log("Add partner error", res);
        yield put(addPartnerCampaignFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(addPartnerCampaignFail(error.message ?? "Có lỗi xảy ra !"));
    }
  });
}

export function* loadPartnershipCampaignDetail() {
  yield takeEvery(FETCH_PARTNER_CAMPAIGN_DETAIL, function* ({ params }) {
    const { id } = params;

    try {
      let url = `/campaigns/get?id=${id}`;

      const res = yield call(
        AmplifyService.apiGetPartnershipCampaignDetail,
        url
      );
      if (res.code === 200) {
        yield put(fetchCampaignDetailSuccess(res.data));
      } else {
        console.log("Load data error", res);
        yield put(fetchCampaignDetailFalse(get(res, "data.error.0", "")));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(
        fetchCampaignDetailFalse(get(err, "message", "Có lỗi xảy ra !"))
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadPartnershipData),
    fork(addNewPartner),
    fork(updatePartnerAccount),
    fork(updatePartnerInfo),
    fork(loadPartnershipCampaignData),
    fork(updatePartnerCampaignStatus),
    fork(deletePartnerCampaign),
    fork(addNewPartnerCampaign),
    fork(loadFullPartnershipData),
    fork(loadPartnershipCampaignDetail),
  ]);
}
