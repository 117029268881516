import {
  IMPORT_RELEASE_SET_LOADING,
  IMPORT_RELEASE_STOP_LOADING,
  FETCH_IMPORT_DATA,
  FETCH_IMPORT_DATA_SUCCESS,
  FETCH_IMPORT_DATA_FALSE,
  CLEAR_IMPORT_DATA_ERROR_MESSAGE,
} from "../constants/ImportRelease";

export function setImportReleaseLoading() {
  return {
    type: IMPORT_RELEASE_SET_LOADING,
    data: {
      loading: true,
    },
  };
}

export function stopImportReleaseLoading() {
  return {
    type: IMPORT_RELEASE_STOP_LOADING,
    data: {
      loading: false,
    },
  };
}

export function fetchImportData(params) {
  return {
    type: FETCH_IMPORT_DATA,
    params: params,
  };
}
export function fetchImportDataSuccess(data) {
  return {
    type: FETCH_IMPORT_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      totalRemain: data.totalRemain,
      totalImport: data.totalImport,
      campaignCode: data.campaignCode,
      campaignId: data.campaignId,
      campaignName: data.campaignName,
      loading: false,
    },
  };
}

export function fetchImportDataFalse(err) {
  return {
    type: FETCH_IMPORT_DATA_FALSE,
    data: {
      err: err,
      loading: false,
    },
  };
}

export function clearImportDataErrMessage() {
  return {
    type: CLEAR_IMPORT_DATA_ERROR_MESSAGE,
    data: {
      err: "",
      loading: false,
    },
  };
}
