import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Voucher from "./Voucher";
import ReleaseVocher from "./ReleaseVocher";
import Partnership from "./Partnership";
import ImportRelease from "./ImportRelease";
import UploadItemsList from "./UploadItemsList";
import ExportRelease from "./ExportRelease";
import GiftVoucher from "./GiftVoucher";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  voucher: Voucher,
  releaseVocher: ReleaseVocher,
  partnership: Partnership,
  importRelease: ImportRelease,
  uploadItems: UploadItemsList,
  exportRelease: ExportRelease,
  giftVoucher: GiftVoucher,
});

export default reducers;
