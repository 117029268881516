import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { UNAUTH_PREFIX_PATH, PREVIEW_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${PREVIEW_PATH}`}
          component={lazy(() => import(`./vouchers`))}
        />
        <Route
          path={`${UNAUTH_PREFIX_PATH}/otp/:code`}
          component={lazy(() => import(`./otp`))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
