import {
  FETCH_UPLOAD_ITEMS,
  FETCH_UPLOAD_ITEMS_SUCCESS,
  FETCH_UPLOAD_ITEMS_ERROR,
  CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE,
  RELEASE_IMPORT,
} from "../constants/UploadItemList";

const initUploadItems = {
  loading: false,
  err: "",
  items: [],
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
  importTime: "",
  totalImportItems: 0,
  totalInvalid: 0,
  fileName: "",
  s3Link: "",
  importStatus: "",
};

const uploadItems = (state = initUploadItems, action) => {
  switch (action.type) {
    case RELEASE_IMPORT:
    case FETCH_UPLOAD_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE:
    case FETCH_UPLOAD_ITEMS_ERROR:
    case FETCH_UPLOAD_ITEMS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default uploadItems;
