const dev = {
  API_ENDPOINT_URL: "https://jsonplaceholder.typicode.com",
  CF_ENDPOINT_URL: "https://d3jf3jr7m7wvgq.cloudfront.net",
  DOMAIN_URL: "http://localhost:3000",
};

const stage = {
  API_ENDPOINT_URL: "https://api.prod.com",
  CF_ENDPOINT_URL: "https://d3jf3jr7m7wvgq.cloudfront.net",
  DOMAIN_URL: "https://loyalty.stage.pnj.io",
};

const prod = {
  API_ENDPOINT_URL: "https://api.prod.com",
  CF_ENDPOINT_URL: "https://d1pfbg9nsbh74i.cloudfront.net",
  DOMAIN_URL: "https://loyalty.pnj.com.vn",
};

const test = {
  API_ENDPOINT_URL: "https://api.test.com",
  CF_ENDPOINT_URL: "https://d3jf3jr7m7wvgq.cloudfront.net",
  DOMAIN_URL: "http://localhost:3000",
};

const envi = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;

const getEnv = () => {
  switch (envi) {
    case "development":
      return dev;
    case "stage":
      return stage;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
