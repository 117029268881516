import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS,
  DELETE_GIFT_VOUCHER_CAMPAIGN,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA,
} from "../constants/GiftVoucher";
import {
  fetchGiftVoucherCampaignData,
  fetchGiftVoucherCampaignDataSuccess,
  fetchGiftVoucherCampaignDataFalse,
  updateGiftVoucherCampaignStatusFail,
  deleteGiftVoucherCampaignFail,
  addGiftVoucherCampaignFail,
  addGiftVoucherCampaignSuccess,
  fetchFullGiftVoucherPartnerDataSuccess,
  fetchFullGiftVoucherPartnerDataFalse,
} from "../actions/GiftVoucher";

import AmplifyService from "services/AmplifyService";
import { get, isEmpty } from "lodash";

export function* loadGiftVoucherCampaignData() {
  yield takeEvery(FETCH_GIFT_VOUCHER_CAMPAIGN_DATA, function* ({ params }) {
    const { keywords, createdAt, pagination, status } = params;

    try {
      let url = `/campaigns/list?page=${pagination.current}&limit=${pagination.pageSize}&prefix=giftvoucher`;

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (status !== "" && status !== "all" && status !== undefined)
        url = `${url}&status=${status}`;
      if (createdAt !== "") url = `${url}&createdAt=${createdAt}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "campaignCode") {
          orderBy = "code";
        } else if (params.sorter.field === "campaignName") {
          orderBy = "name";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        } else if (params.sorter.field === "updatedDate") {
          orderBy = "updated_at";
        } else if (params.sorter.field === "partnerName") {
          orderBy = "partner_name";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGetGiftVoucherCampaign, url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total ?? 0;
        yield put(
          fetchGiftVoucherCampaignDataSuccess({
            items: res.data.items ?? [],
            currentPage: res.data.currentPage ?? 1,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(
          fetchGiftVoucherCampaignDataFalse(get(res, "data.error.0", ""))
        );
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(
        fetchGiftVoucherCampaignDataFalse(
          get(err, "message", "Có lỗi xảy ra !")
        )
      );
    }
  });
}

export function* updateGiftVoucherCampaignStatus() {
  yield takeEvery(UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS, function* ({ params }) {
    const { id, updatedBy, status, _function } = params;

    try {
      const body = {
        id: id,
        status: status,
        updatedBy: updatedBy,
        // _function: _function,
      };

      const res = yield call(
        AmplifyService.updateStatusVoucherById,
        "pnjLoyalty",
        "/campaigns/update-status",
        body
      );
      if (res.code === 200) {
        yield put(
          fetchGiftVoucherCampaignData({
            keywords: "",
            createdAt: "",
            status: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            // sorter: {
            //   field: "updatedDate",
            //   order: "desc",
            // },
          })
        );
      } else {
        console.log("Update partner campaign status error", res);
        yield put(
          updateGiftVoucherCampaignStatusFail(get(res, "data.error.0", ""))
        );
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(
        updateGiftVoucherCampaignStatusFail(
          get(error, "message", "Có lỗi xảy ra !")
        )
      );
    }
  });
}

export function* deleteGiftVoucherCampaign() {
  yield takeEvery(DELETE_GIFT_VOUCHER_CAMPAIGN, function* ({ params }) {
    const { id } = params;

    try {
      const url = `/campaigns/delete?id=${id}`;
      const res = yield call(AmplifyService.deleteVoucher, "pnjLoyalty", url);

      if (res.code === 200) {
        yield put(
          fetchGiftVoucherCampaignData({
            keywords: "",
            createdAt: "",
            status: "",
            pagination: {
              defaultPageSize: 10,
              total: 0,
              current: 1,
              pageSize: 10,
            },
            sorter: {
              field: "id",
              order: "desc",
            },
          })
        );
      } else {
        console.log("delete partner campaign error", res);
        yield put(deleteGiftVoucherCampaignFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(
        deleteGiftVoucherCampaignFail(get(error, "message", "Có lỗi xảy ra !"))
      );
    }
  });
}

export function* addNewPartnerCampaign() {
  yield takeEvery(ADD_NEW_GIFT_VOUCHER_CAMPAIGN, function* ({ params }) {
    const {
      partnerId,
      campaignName,
      campaignCode,
      startAt,
      endAt,
      timeRemaining,
      description,
      type,
      limit,
      mediaS3,
      value,
      price,
      percent,
      createdBy,
      applyChannel,
      accountingType,
      totalFrom,
      totalTo,
      commonCode,
      note,
    } = params;

    try {
      const body = {
        prefix: "giftvoucher",
        partnerId: partnerId ?? null,
        name: campaignName ?? "",
        code: campaignCode ?? "",
        startAt: startAt ?? null,
        endAt: endAt ?? null,
        timeRemaining: timeRemaining ?? 0,
        description: description ?? "",
        type: type ?? "",
        limit: limit ?? null,
        categoryId: null,
        mediaS3: mediaS3 ?? [],
        value: value ?? null,
        price: price ?? null,
        percent: percent ?? 0,
        createdBy: createdBy ?? "",
        applyChannel: applyChannel ?? null,
        accountingType: accountingType ?? "",
        totalFrom: totalFrom ?? 0,
        totalTo: totalTo ?? 0,
        commonCode: commonCode ?? false,
        note: note ?? "",
      };

      const res = yield call(AmplifyService.addPartnerCampaign, body);
      if (res.code === 200) {
        yield put(addGiftVoucherCampaignSuccess("Add new campaign success!"));
      } else {
        console.log("Add partner error", res);
        yield put(addGiftVoucherCampaignFail(get(res, "data.error.0", "")));
      }
    } catch (error) {
      console.log("Exception", error);
      yield put(addGiftVoucherCampaignFail(error.message ?? "Có lỗi xảy ra !"));
    }
  });
}

export function* loadFullPartnershipData() {
  yield takeEvery(FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA, function* () {
    try {
      let url = `/partners/list?page=1&limit=500`;

      const res = yield call(AmplifyService.apiGetPartnership, url);
      if (res.code === 200) {
        yield put(fetchFullGiftVoucherPartnerDataSuccess(res.data.items));
      } else {
        console.log("Load data error", res);
        yield put(
          fetchFullGiftVoucherPartnerDataFalse(get(res, "data.error.0", ""))
        );
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(
        fetchFullGiftVoucherPartnerDataFalse(
          get(err, "message", "Có lỗi xảy ra !")
        )
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadGiftVoucherCampaignData),
    fork(updateGiftVoucherCampaignStatus),
    fork(deleteGiftVoucherCampaign),
    fork(addNewPartnerCampaign),
    fork(loadFullPartnershipData),
  ]);
}
