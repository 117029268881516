import {
  EXPORT_RELEASE_SET_LOADING,
  EXPORT_RELEASE_STOP_LOADING,
  FETCH_EXPORT_DATA,
  FETCH_EXPORT_DATA_SUCCESS,
  FETCH_EXPORT_DATA_FALSE,
  CLEAR_EXPORT_DATA_ERROR_MESSAGE,
} from "../constants/ExportRelease";

export function setExportReleaseLoading() {
  return {
    type: EXPORT_RELEASE_SET_LOADING,
    data: {
      loading: true,
    },
  };
}

export function stopExportReleaseLoading() {
  return {
    type: EXPORT_RELEASE_STOP_LOADING,
    data: {
      loading: false,
    },
  };
}

export function fetchExportData(params) {
  return {
    type: FETCH_EXPORT_DATA,
    params: params,
  };
}
export function fetchExportDataSuccess(data) {
  return {
    type: FETCH_EXPORT_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      totalRemain: data.totalRemain,
      totalImport: data.totalImport,
      campaignCode: data.campaignCode,
      campaignId: data.campaignId,
      campaignName: data.campaignName,
      loading: false,
    },
  };
}

export function fetchExportDataFalse(err) {
  return {
    type: FETCH_EXPORT_DATA_FALSE,
    data: {
      err: err,
      loading: false,
    },
  };
}

export function clearExportDataErrMessage() {
  return {
    type: CLEAR_EXPORT_DATA_ERROR_MESSAGE,
    data: {
      err: "",
      loading: false,
    },
  };
}
