import {
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA,
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS,
  FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR,
  CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS,
  UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR,
  GIFT_VOUCHER_SET_LOADING,
  GIFT_VOUCHER_STOP_LOADING,
  ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS,
  ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR,
  FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS,
} from "../constants/GiftVoucher";

const initGiftVoucher = {
  campaign: {
    items: [],
    currentPage: 1,
    pagination: {
      defaultPageSize: 10,
      total: 0,
      current: 1,
      pageSize: 10,
    },
    loading: false,
    errMessage: "",
    message: "",
    campaignDetail: {},
    initFormData: {},
  },
  allPartner: [],
  loading: false,
  errMessage: "",
};

const giftVoucher = (state = initGiftVoucher, action) => {
  switch (action.type) {
    case GIFT_VOUCHER_SET_LOADING:
    case UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS:
    case FETCH_GIFT_VOUCHER_CAMPAIGN_DATA:
    case ADD_NEW_GIFT_VOUCHER_CAMPAIGN:
      return {
        ...state,
        campaign: { ...state.campaign, ...{ loading: true } },
      };
    case GIFT_VOUCHER_STOP_LOADING:
      return {
        ...state,
        campaign: { ...state.campaign, ...{ loading: false } },
      };
    case FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS:
    case FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR:
    case UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS:
    case UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR:
    case ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN:
    case CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN:
    case ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS:
    case ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL:
    case FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR:
      return {
        ...state,
        campaign: { ...state.campaign, ...action.data },
      };
    case FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default giftVoucher;
