import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_COGNITO_AUTHENTICATED,
  SIGNIN_WITH_EMAIL,
  REQUIRED_NEW_PASSWORD,
  SIGNIN_EMAIL_AUTHENTICATED,
} from "../constants/Auth";

let storageData = localStorage.getItem(AUTH_TOKEN);
let token = {};
if (storageData !== null) {
  try {
    token = JSON.parse(storageData);
  } catch (error) {
    console.log("error", error);
    token = null;
  }
}

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: token,
  user: {},
  showInputNewPass: false,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNIN_WITH_COGNITO_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_EMAIL: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUIRED_NEW_PASSWORD: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SIGNIN_EMAIL_AUTHENTICATED: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default auth;
