import {
  FETCH_UPLOAD_ITEMS,
  FETCH_UPLOAD_ITEMS_SUCCESS,
  FETCH_UPLOAD_ITEMS_ERROR,
  CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE,
  DELETE_IMPORT,
  RELEASE_IMPORT,
} from "../constants/UploadItemList";

export function fetchUploadItems(params) {
  return {
    type: FETCH_UPLOAD_ITEMS,
    params: params,
  };
}

export function deleteImportRelesae(params) {
  return {
    type: DELETE_IMPORT,
    params: params,
  };
}

export function releaseImport(params) {
  return {
    type: RELEASE_IMPORT,
    params: params,
  };
}

export function fetchUploadItemsSuccess(data) {
  return {
    type: FETCH_UPLOAD_ITEMS_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
      importTime: data.importTime,
      totalImportItems: data.totalImportItems,
      totalInvalid: data.totalInvalid,
      fileName: data.fileName,
      s3Link: data.s3Link,
      importStatus: data.importStatus,
      campaignCode: data.campaignCode,
      campaignId: data.campaignId,
      campaignName: data.campaignName,
    },
  };
}

export function fetchUploadItemsFalse(err) {
  return {
    type: FETCH_UPLOAD_ITEMS_ERROR,
    data: {
      err: err,
      loading: false,
    },
  };
}

export function clearUploadItemsErrMessage() {
  return {
    type: CLEAR_UPLOAD_ITEMS_ERROR_MESSAGE,
    data: {
      err: "",
      loading: false,
    },
  };
}
