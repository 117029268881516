import React, { Component } from "react";
import { Icon } from "@iconify/react";

export class IconCustom extends Component {
  render() {
    const { type, className } = this.props;
    return <Icon icon={type} className={className} />;
  }
}

export default IconCustom;
