import { get } from "lodash";
import moment from "moment";
import {
  FETCH_PARTNER_DATA_SUCCESS,
  FETCH_PARTNER_DATA_FALSE,
  FETCH_PARTNER_DATA,
  FETCH_PARTNER_DATA_DETAIL,
  FETCH_PARTNER_DETAI_SUCCESS,
  ADD_NEW_PARTNER_FAIL,
  ADD_NEW_PARTNER,
  CLEAR_ERROR_MESSAGE,
  UPDATE_PARTNER_ACCOUNT,
  UPDATE_PARTNER_ACCOUNT_FAIL,
  UPDATE_PARTNER_INFO,
  UPDATE_PARTNER_INFO_FAIL,
  FETCH_PARTNER_CAMPAIGN_DATA,
  FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS,
  FETCH_PARTNER_CAMPAIGN_DATA_ERROR,
  CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN,
  UPDATE_PARTNER_CAMPAIGN_DATA,
  UPDATE_PARTNER_CAMPAIGN_STATUS,
  UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR,
  DELETE_PARTNER_CAMPAIGN,
  DELETE_PARTNER_CAMPAIGN_ERROR,
  SET_LOADING,
  STOP_LOADING,
  ADD_ERROR_MESSAGE_PARTNER_CAMPAIN,
  ADD_NEW_PARTNER_CAMPAIGN,
  ADD_NEW_PARTNER_CAMPAIGN_FAIL,
  ADD_NEW_PARTNER_CAMPAIGN_SUCCESS,
  FETCH_FULL_PARTNER_DATA_SUCCESS,
  FETCH_FULL_PARTNER_DATA_ERROR,
  FETCH_PARTNER_CAMPAIGN_DETAIL,
  FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR,
  FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS,
} from "../constants/Partnership";
import { DOMAIN_URL, PREVIEW_PATH } from "configs/AppConfig";

const initVoucher = {
  items: [],
  currentPage: 1,
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
  campaign: {
    items: [],
    currentPage: 1,
    pagination: {
      defaultPageSize: 10,
      total: 0,
      current: 1,
      pageSize: 10,
    },
    loading: false,
    errMessage: "",
    message: "",
    campaignDetail: {},
    initFormData: {},
  },
  allPartner: [],
  loading: false,
  errMessage: "",
};

const partnership = (state = initVoucher, action) => {
  switch (action.type) {
    case FETCH_PARTNER_DATA:
    case FETCH_PARTNER_DATA_DETAIL:
      return {
        ...state,
        ...{ loading: true },
      };
    case FETCH_PARTNER_DETAI_SUCCESS:
    case FETCH_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case UPDATE_PARTNER_INFO:
    case UPDATE_PARTNER_ACCOUNT:
    case ADD_NEW_PARTNER:
      return {
        ...state,
        ...{ loading: true },
      };
    case CLEAR_ERROR_MESSAGE:
    case UPDATE_PARTNER_ACCOUNT_FAIL:
    case UPDATE_PARTNER_INFO_FAIL:
    case ADD_NEW_PARTNER_FAIL:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_PARTNER_DATA_FALSE:
      return {
        ...state,
        ...{
          items: [],
          currentPage: 1,
          pagination: {
            defaultPageSize: 10,
            total: 0,
            current: 1,
            pageSize: 10,
          },
          loading: false,
        },
        ...action.data,
      };
    case FETCH_PARTNER_CAMPAIGN_DETAIL:
    case ADD_NEW_PARTNER_CAMPAIGN:
    case DELETE_PARTNER_CAMPAIGN:
    case UPDATE_PARTNER_CAMPAIGN_DATA:
    case UPDATE_PARTNER_CAMPAIGN_STATUS:
    case SET_LOADING:
    case FETCH_PARTNER_CAMPAIGN_DATA:
      return {
        ...state,
        campaign: { ...state.campaign, ...{ loading: true } },
      };
    case STOP_LOADING:
      return {
        ...state,
        campaign: { ...state.campaign, ...{ loading: false } },
      };
    case FETCH_PARTNER_CAMPAIGN_DETAI_SUCCESS:
      let oldFileList = [];
      for (let s3Object of get(action.data, "campaignDetail.media_s3", [])) {
        oldFileList.push({
          uid: s3Object.id,
          name: s3Object.file_name,
          status: "done",
          url: s3Object.url,
        });
      }
      return {
        ...state,
        campaign: {
          ...state.campaign,
          loading: false,
          // ...action.data,
          initFormData: {
            id: get(action.data, "campaignDetail.id", ""),
            partnerName: get(action.data, "campaignDetail.partner.name", ""),
            partnerCode: get(action.data, "campaignDetail.partner.code", ""),
            campaignName: get(action.data, "campaignDetail.name", ""),
            campaignCode: get(action.data, "campaignDetail.code", ""),
            startEndDate: [
              moment(
                get(action.data, "campaignDetail.start_at", ""),
                "YYYY-MM-DD HH:mm:ss"
              ).add(7, "hours"),
              moment(
                get(action.data, "campaignDetail.end_at", ""),
                "YYYY-MM-DD HH:mm:ss"
              ).add(7, "hours"),
            ],
            paymentType: get(
              action.data,
              "campaignDetail.accounting_type",
              "payment"
            ),
            description: get(action.data, "campaignDetail.description", ""),
            limit: get(action.data, "campaignDetail.limit", ""),
            createdBy: get(action.data, "campaignDetail.created_by", ""),
            approvedBy: get(action.data, "campaignDetail.approved_by", ""),
            createdAt: moment(get(action.data, "campaignDetail.created_at", ""))
              .add(7, "hours")
              .format("DD/MM/YYYY"),
            voucherLink:
              DOMAIN_URL +
              PREVIEW_PATH +
              "/" +
              get(action.data, "campaignDetail.code", ""),
            timeRemaining:
              get(action.data, "campaignDetail.type", "") === "coupon"
                ? parseInt(
                    get(
                      action.data,
                      "campaignDetail.campaign_vouchers.0.time_remaining",
                      0
                    )
                  ) / 24
                : parseInt(
                    get(
                      action.data,
                      "campaignDetail.campaign_coupons.0.time_remaining",
                      0
                    )
                  ) / 24,
            value: get(
              action.data,
              "campaignDetail.campaign_vouchers.0.value",
              0
            ),
            percent: get(
              action.data,
              "campaignDetail.campaign_coupons.0.percent",
              0
            ),
            mediaS3: oldFileList,
          },
        },
      };
    case FETCH_PARTNER_CAMPAIGN_DETAIL_ERROR:
    case FETCH_FULL_PARTNER_DATA_ERROR:
    case ADD_NEW_PARTNER_CAMPAIGN_FAIL:
    case ADD_NEW_PARTNER_CAMPAIGN_SUCCESS:
    case ADD_ERROR_MESSAGE_PARTNER_CAMPAIN:
    case DELETE_PARTNER_CAMPAIGN_ERROR:
    case CLEAR_ERROR_MESSAGE_PARTNER_CAMPAIN:
    case FETCH_PARTNER_CAMPAIGN_DATA_SUCCESS:
    case FETCH_PARTNER_CAMPAIGN_DATA_ERROR:
    case UPDATE_PARTNER_CAMPAIGN_STATUS_ERROR:
      return {
        ...state,
        campaign: { ...state.campaign, ...action.data },
      };
    case FETCH_FULL_PARTNER_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default partnership;
