export const FETCH_RELEASE_VOUCHER_DATA = "FETCH_RELEASE_VOUCHER_DATA";
export const FETCH_RELEASE_VOUCHER_DATA_SUCCESS =
  "FETCH_RELEASE_VOUCHER_DATA_SUCCESS";
export const FETCH_RELEASE_VOUCHER_DATA_FALSE =
  "FETCH_RELEASE_VOUCHER_DATA_FALSE";
export const FETCH_PARTNER_RELEASE_VOUCHER_DATA =
  "FETCH_PARTNER_RELEASE_VOUCHER_DATA";
export const FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS =
  "FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS";
export const FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE =
  "FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE";
export const FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA =
  "FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA";
export const FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS =
  "FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS";
export const FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE =
  "FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE";
export const FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA =
  "FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA";
export const FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS =
  "FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS";
export const FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE =
  "FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE";
