import {
  FETCH_RELEASE_VOUCHER_DATA,
  FETCH_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS,
  FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE,
} from "../constants/ReleaseVoucher";

export function fetchReleaseVoucherData(params) {
  return {
    type: FETCH_RELEASE_VOUCHER_DATA,
    params: params,
  };
}
export function fetchReleaseVoucherDataSuccess(data) {
  return {
    type: FETCH_RELEASE_VOUCHER_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchReleaseVoucherDataFalse() {
  return {
    type: FETCH_RELEASE_VOUCHER_DATA_FALSE,
  };
}

export function fetchPartnerReleaseVoucherData(params) {
  return {
    type: FETCH_PARTNER_RELEASE_VOUCHER_DATA,
    params: params,
  };
}
export function fetchPartnerReleaseVoucherDataSuccess(data) {
  return {
    type: FETCH_PARTNER_RELEASE_VOUCHER_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchPartnerReleaseVoucherDataFalse() {
  return {
    type: FETCH_PARTNER_RELEASE_VOUCHER_DATA_FALSE,
  };
}

export function fetchPartnerClientReleaseVoucherData(params) {
  return {
    type: FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA,
    params: params,
  };
}
export function fetchPartnerClientReleaseVoucherDataSuccess(data) {
  return {
    type: FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchPartnerClientReleaseVoucherDataFalse() {
  return {
    type: FETCH_PARTNER_CLIENT_RELEASE_VOUCHER_DATA_FALSE,
  };
}

export function fetchGiftVoucherReleaseVoucherData(params) {
  return {
    type: FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA,
    params: params,
  };
}
export function fetchGiftVoucherReleaseVoucherDataSuccess(data) {
  return {
    type: FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_SUCCESS,
    data: {
      items: data.items,
      currentPage: data.currentPage,
      pagination: data.pagination,
      loading: false,
    },
  };
}

export function fetchGiftVoucherReleaseVoucherDataFalse() {
  return {
    type: FETCH_GIFT_VOUCHER_RELEASE_VOUCHER_DATA_FALSE,
  };
}
