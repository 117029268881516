import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import UnAuthLayout from "layouts/unauth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider, Spin } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  UNAUTH_PREFIX_PATH,
  PREVIEW_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { useSelector, useDispatch } from "react-redux";
import { Hub, Auth } from "aws-amplify";
import { signOut, signInCognito, showAuthMessage } from "../redux/actions/Auth";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { location } = props;
  const locale = useSelector((state) => state.theme.locale);
  const direction = useSelector((state) => state.auth.direction);
  const loading = useSelector((state) => state.auth.loading);
  const currentAppLocale = AppLocale[locale];
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  Hub.listen("auth", ({ payload: { event, data } }) => {
    // eslint-disable-next-line default-case
    switch (event) {
      case "signOut":
        dispatch(signOut());
        break;
      case "signIn_failure":
        console.log("signIn_failure.....");
        dispatch(
          showAuthMessage(
            data.message ??
              "You don't have permission to access this resource !"
          )
        );
        break;
    }
  });

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (!isCancelled) {
        await Auth.currentAuthenticatedUser()
          .then((data) => {
            dispatch(signInCognito(data));
          })
          .catch((err) => {
            dispatch(signOut());
          });
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, []);

  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={PREVIEW_PATH}>
            <UnAuthLayout direction={direction} />
          </Route>
          <Route path={UNAUTH_PREFIX_PATH}>
            <UnAuthLayout direction={direction} />
          </Route>
          <RouteInterceptor
            path={APP_PREFIX_PATH}
            // isAuthenticated={checkAuthorization(location, token)}
            isAuthenticated={token}
          >
            <Spin spinning={loading}>
              <AppLayout direction={direction} location={location} />
            </Spin>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default withRouter(Views);
