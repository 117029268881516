export const FETCH_GIFT_VOUCHER_CAMPAIGN_DATA =
  "FETCH_GIFT_VOUCHER_CAMPAIGN_DATA";
export const FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS =
  "FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_SUCCESS";
export const FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR =
  "FETCH_GIFT_VOUCHER_CAMPAIGN_DATA_ERROR";
export const CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN =
  "CLEAR_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN";
export const UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS =
  "UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS";
export const UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR =
  "UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_ERROR";
export const UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS =
  "UPDATE_GIFT_VOUCHER_CAMPAIGN_STATUS_SUCCESS";
export const DELETE_GIFT_VOUCHER_CAMPAIGN = "DELETE_GIFT_VOUCHER_CAMPAIGN";
export const DELETE_GIFT_VOUCHER_CAMPAIGN_ERROR =
  "DELETE_GIFT_VOUCHER_CAMPAIGN_ERROR";
export const DELETE_GIFT_VOUCHER_CAMPAIGN_SUCCESS =
  "DELETE_GIFT_VOUCHER_CAMPAIGN_SUCCESS";
export const GIFT_VOUCHER_SET_LOADING = "GIFT_VOUCHER_SET_LOADING";
export const GIFT_VOUCHER_STOP_LOADING = "GIFT_VOUCHER_STOP_LOADING";
export const ADD_NEW_GIFT_VOUCHER_CAMPAIGN = "ADD_NEW_GIFT_VOUCHER_CAMPAIGN";
export const ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL =
  "ADD_NEW_GIFT_VOUCHER_CAMPAIGN_FAIL";
export const ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS =
  "ADD_NEW_GIFT_VOUCHER_CAMPAIGN_SUCCESS";
export const FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA =
  "FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA";
export const FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS =
  "FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_SUCCESS";
export const FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR =
  "FETCH_FULL_GIFT_VOUCHER_PARTNER_DATA_ERROR";
export const ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN =
  "ADD_ERROR_MESSAGE_GIFT_VOUCHER_CAMPAIN";
