import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Spin } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import AuthorizationConfig from "configs/AuthorizationConfig";
import { get } from "lodash";
import AmplifyService from "../../services/AmplifyService";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  return ["vouchers-default", "partnership-default", "giftVouchersDefault"];
};

const MenuContent = (props) => {
  const [roles, setRoles] = useState([{ title: "Unauthorized" }]);
  const [loading, setLoading] = useState(false);

  const SideNavContent = (props) => {
    const {
      sideNavTheme,
      routeInfo,
      hideGroupTitle,
      localization,
      onMobileNavToggle,
    } = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
    const closeMobileNav = () => {
      if (isMobile) {
        onMobileNavToggle(false);
      }
    };

    return (
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
        selectedKeys={[routeInfo?.key]}
      >
        {navigationConfig.map((menu) =>
          checkAuthorization(menu.key) && menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                checkAuthorization(subMenuFirst.key) &&
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon
                          type={subMenuFirst?.icon}
                          className="ant-menu-item-icon"
                        />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) =>
                      checkAuthorization(subMenuSecond.key) ? (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? (
                            <Icon
                              type={subMenuSecond?.icon}
                              className="ant-menu-item-icon"
                            />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuSecond.path}
                          />
                        </Menu.Item>
                      ) : null
                    )}
                  </SubMenu>
                ) : checkAuthorization(subMenuFirst.key) ? (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon
                        type={subMenuFirst.icon}
                        className="ant-menu-item-icon"
                      />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                ) : null
              )}
            </Menu.ItemGroup>
          ) : checkAuthorization(menu.key) ? (
            <Menu.Item key={menu.key}>
              {menu.icon ? (
                <Icon type={menu?.icon} className="ant-menu-item-icon" />
              ) : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          ) : null
        )}
      </Menu>
    );
  };

  const TopNavContent = (props) => {
    const { topNavColor, localization } = props;
    return (
      <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
        {navigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.key}
              popupClassName="top-nav-menu"
              title={
                <span className="d-flex align-items-center">
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu.title)}</span>
                </span>
              }
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    key={subMenuFirst.key}
                    popupClassName="top-nav-menu"
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link to={subMenuFirst.path} />
                  </Menu.Item>
                )
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>
          )
        )}
      </Menu>
    );
  };

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (!isCancelled) {
        setLoading(true);
      }
      const res = await AmplifyService.apiGetRole();
      if (get(res, "code", "") === 200) {
        const roles = get(res, "data.roles", [{ title: "Unauthorized" }]);
        if (!isCancelled) {
          setRoles(roles);
          setLoading(false);
        }
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, []);

  const checkAuthorization = (menuKey) => {
    let returnValue = false;

    for (let role of roles) {
      if (AuthorizationConfig[role["title"]]["menu"].includes(menuKey)) {
        returnValue = true;
        break;
      }
    }

    return returnValue;
  };

  return (
    <Spin
      spinning={loading}
      style={{
        height: "100%",
        width: "100%",
        textAlign: "center",
        paddingTop: "50%",
      }}
    >
      {props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
      ) : (
        <TopNavContent {...props} />
      )}
    </Spin>
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
