const AuthorizationConfig = {
  admin: {
    menu: [
      "campaigns",
      "vouchers",
      "vouchers-default",
      "create-voucher",
      "list-voucher",
      "partnership",
      "partnership-default",
      "list-partnership",
      "list-release",
      "partner-campaign-create",
      "partner-campaign-list",
      "home",
      "home-default",
      "giftVouchers",
      "giftVouchersDefault",
      "giftVouchersCampaign",
      "giftVouchersReleaseList",
    ],
  },
  admin_giftvoucher: {
    menu: [
      "home-default",
      "giftVouchers",
      "giftVouchersDefault",
      "giftVouchersCampaign",
      "giftVouchersReleaseList",
    ],
  },
  admin_evoucher: {
    menu: [
      "campaigns",
      "vouchers",
      "vouchers-default",
      "create-voucher",
      "list-voucher",
      "list-release",
    ],
  },
  admin_partner: {
    menu: [
      "partnership",
      "partnership-default",
      "list-partnership",
      "partner-campaign-create",
      "partner-campaign-list",
      "home",
      "home-default",
    ],
  },
  client_partner: {
    menu: [
      "partnerclient",
      "partnerclient-default",
      "partnerclient-campaign",
      "partnerclient-release",
      "home",
      "home-default",
    ],
  },
  user: {
    menu: ["home", "home-default"],
  },
  Unauthorized: {
    menu: ["home", "home-default"],
  },
};

export default AuthorizationConfig;
