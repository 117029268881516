import { Auth, API, Storage } from "aws-amplify";
import { CF_ENDPOINT_URL } from "configs/AppConfig";
import { isEmpty } from "lodash";

const AmplifyService = {};

AmplifyService.signInEmailRequest = async () =>
  await Auth.federatedSignIn({
    provider: "PNJ-SignIn",
  });

AmplifyService.currentAuthenticatedUser = async () =>
  await Auth.currentAuthenticatedUser()
    .then((data) => data)
    .catch((err) => err);

AmplifyService.signOutRequest = async () =>
  await Auth.signOut()
    .then((user) => user)
    .catch((err) => err);

AmplifyService.apiGet = async (apiName, path) =>
  await API.get(apiName, path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.updateVoucherById = async (apiName, path, body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post(apiName, path, config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.uploadFileToS3 = async (image) => {
  const res = await Storage.put(image.name, image.originFileObj, {
    contentType: image.type,
  })
    .then(async (data) => {
      return {
        code: 200,
        file_name: data["key"],
        url: `${CF_ENDPOINT_URL}/public/${data["key"]}`,
      };
    })
    .catch((err) => {
      return {
        code: err.$metadata.httpStatusCode,
        message: "Upload false with err: " + err.message,
      };
    });

  return res;
};

AmplifyService.getFile = async (key, type) => {
  return await Storage.get(key, {
    contentType: type,
  });
};

AmplifyService.removeFile = async (key) => {
  await Storage.remove(`public/${key}`)
    .then((result) => result)
    .catch((err) => err);
};

AmplifyService.updateStatusVoucherById = async (apiName, path, body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post(apiName, path, config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.deleteVoucher = async (apiName, path) => {
  const config = {
    headers: {},
  };
  return await API.del(apiName, path, config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.resendEvocher = async (apiName, path, body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post(apiName, path, config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.refreshToken = async () => {
  const config = {
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/users/get-token", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.deleteToken = async () => {
  const config = {
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/users/logout", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.apiGetRole = async () =>
  await API.get("pnjLoyalty", "/users/get-auth-roles")
    .then((res) => res)
    .catch((err) => err);

AmplifyService.apiGetPartnership = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.addPartner = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/partners/create", config)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
};

AmplifyService.updatePartnerInfo = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/partners/update", config)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
};

AmplifyService.updatePartnerAccount = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/partners/update-account", config)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
};

AmplifyService.apiGetPartnershipCampaign = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.addPartnerCampaign = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/create", config)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
};

AmplifyService.apiGetPartnershipCampaignDetail = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.updatePartnerCampaignById = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/update", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.apiGetCampaignReleaseRemain = async (id) => {
  const path = `/campaigns/get-quantity?id=${id}`;
  return await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.uploadFileChangeNameToS3 = async (file, name) => {
  const res = await Storage.put(name, file.originFileObj)
    .then(async (data) => {
      return {
        code: 200,
        file_name: data["key"],
      };
    })
    .catch((err) => {
      return {
        code: err.$metadata.httpStatusCode,
        message: "Upload false with err: " + err.message,
      };
    });

  return res;
};

AmplifyService.createUploadRelease = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/import-release-list", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.apiGetImportRelease = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.apiGetUploadItems = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.deleteImportRelease = async (id) => {
  const config = {
    headers: {},
  };
  return await API.del(
    "pnjLoyalty",
    `/campaigns/imports/delete?id=${id}`,
    config
  )
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.releaseImport = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/imports/release", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.apiGetParnerInfo = async () =>
  await API.get("pnjLoyalty", "/partners/get")
    .then((res) => res)
    .catch((err) => err);

AmplifyService.apiGetExportRelease = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.exportReleaseByCampaignId = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/export-vouchers", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.updateReceiveDateExportRelease = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post(
    "pnjLoyalty",
    "/campaigns/exports/update-receiveat",
    config
  )
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.apiGetGiftVoucherCampaign = async (path) =>
  await API.get("pnjLoyalty", path)
    .then((res) => res)
    .catch((err) => err);

AmplifyService.postRequestOTP = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/get-otp", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.postVerifyOTP = async (body) => {
  const config = {
    body: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  return await API.post("pnjLoyalty", "/campaigns/verify-otp", config)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.dashboardVoucherUsed = async (campaignId, fromDate, toDate) => {
  let url = `/dashboard/voucher-used?from=${fromDate}&to=${toDate}`;

  if (!isEmpty(campaignId)) {
    url += `&campaignId=${campaignId}`;
  }
  return await API.get("pnjLoyalty", url)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.dashboardVoucherReleased = async (
  campaignId,
  fromDate,
  toDate
) => {
  let url = `/dashboard/voucher-released?from=${fromDate}&to=${toDate}`;

  if (!isEmpty(campaignId)) {
    url += `&campaignId=${campaignId}`;
  }
  return await API.get("pnjLoyalty", url)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.dashboardGetAllCampaignRunning = async (
  campaignId,
  fromDate,
  toDate
) => {
  let url = `/dashboard/count-campaigns-running`;

  return await API.get("pnjLoyalty", url)
    .then((res) => res)
    .catch((err) => err);
};

AmplifyService.dashboardGetReleaseByDate = async (
  campaignId,
  fromDate,
  toDate,
  type
) => {
  let url = `/dashboard/group-count?from=${fromDate} 00:00:00&to=${toDate} 00:00:00&groupType=${type}`;

  if (!isEmpty(campaignId)) {
    url += `&campaignId=${campaignId}`;
  }

  return await API.get("pnjLoyalty", url)
    .then((res) => res)
    .catch((err) => err);
};

export default AmplifyService;
