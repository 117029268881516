import {
  EXPORT_RELEASE_SET_LOADING,
  EXPORT_RELEASE_STOP_LOADING,
  FETCH_EXPORT_DATA,
  FETCH_EXPORT_DATA_SUCCESS,
  FETCH_EXPORT_DATA_FALSE,
  CLEAR_EXPORT_DATA_ERROR_MESSAGE,
} from "../constants/ExportRelease";

const initExportRelease = {
  loading: false,
  err: "",
  items: [],
  pagination: {
    defaultPageSize: 10,
    total: 0,
    current: 1,
    pageSize: 10,
  },
};

const exportRelease = (state = initExportRelease, action) => {
  switch (action.type) {
    case FETCH_EXPORT_DATA:
    case EXPORT_RELEASE_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_RELEASE_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_EXPORT_DATA_ERROR_MESSAGE:
    case FETCH_EXPORT_DATA_FALSE:
    case FETCH_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default exportRelease;
