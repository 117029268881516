import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure({ ...awsExports, ssr: true });

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
