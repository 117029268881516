import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Voucher from "./Voucher";
import Releasevoucher from "./ReleaseVoucher";
import Partnership from "./Partnership";
import ImportRelease from "./ImportRelease";
import ExportRelease from "./ExportRelease";
import UploadItemsList from "./UploadItemsList";
import GiftVoucher from "./GiftVoucher";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Voucher(),
    Releasevoucher(),
    Partnership(),
    UploadItemsList(),
    ImportRelease(),
    ExportRelease(),
    GiftVoucher(),
  ]);
}
