import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { FETCH_DATA, FETCH_DATA_DETAIL } from "../constants/Voucher";
import {
  fetchDataSuccess,
  fetchDataFalse,
  fetchDataDetailSuccess,
  fetchDataDetailFalse,
} from "../actions/Voucher";

import AmplifyService from "services/AmplifyService";
import { get, isEmpty } from "lodash";

export function* loadVoucher() {
  yield takeEvery(FETCH_DATA, function* ({ params }) {
    const { keywords, status, createdAt, pagination } = params;
    try {
      let url = `/vouchers/list?page=${pagination.current}&limit=${pagination.pageSize}`;

      if (keywords !== "") url = `${url}&keywords=${keywords}`;
      if (status !== "" && status !== "all" && status !== undefined)
        url = `${url}&status=${status}`;
      if (createdAt !== "") url = `${url}&createdAt=${createdAt}`;

      if (!isEmpty(get(params, "sorter", {}))) {
        let orderBy = params.sorter.field;
        if (params.sorter.field === "campaignCode") {
          orderBy = "code";
        } else if (params.sorter.field === "campaignName") {
          orderBy = "name";
        } else if (params.sorter.field === "createdDate") {
          orderBy = "created_at";
        }

        url = `${url}&orderBy=${orderBy}&orderType=${
          params.sorter.order === "ascend" ? "asc" : "desc"
        }`;
      } else {
        url = `${url}&orderBy=id&orderType=desc`;
      }

      const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
      if (res.code === 200) {
        const returnPagination = { ...pagination };

        returnPagination.total = res.data.total;
        yield put(
          fetchDataSuccess({
            items: res.data.items,
            currentPage: res.data.currentPage,
            pagination: returnPagination,
          })
        );
      } else {
        console.log("Load data error", res);
        yield put(fetchDataFalse(res));
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchDataFalse(err));
    }
  });
}

export function* loadVoucherDetail() {
  yield takeEvery(FETCH_DATA_DETAIL, function* ({ id }) {
    try {
      let url = `/vouchers/get?id=${id}`;
      const res = yield call(AmplifyService.apiGet, "pnjLoyalty", url);
      if (res.code === 200) {
        yield put(fetchDataDetailSuccess(res.data));
      } else {
        console.log("Load data error", res);

        yield put(fetchDataDetailFalse());
      }
    } catch (err) {
      console.log("===ERROR===", err);
      yield put(fetchDataDetailFalse());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadVoucher), fork(loadVoucherDetail)]);
}
